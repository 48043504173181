import {useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import styled from 'styled-components';
import devices from '../devices';

import Step from './Step';
import {useConfig} from '../ConfigProvider';
import {useTunnelData} from "../TunnelContext";
import ClubsMap from "../ClubsMap";
import Button from "../Button";
import StepHeader from "../StepHeader";
import GeoSearch from '../GeoSearch';
import Confirm from '../Confirm';
import ClubList from '../ClubList';
import StyledStepFooter from '../StyledStepFooter';

function pushEvent(e) {
	if (!window.onairEvent)
		return;
	
	if( !window.dataLayer ) {
		console.warn("Cannot push event", e);
		return false;
	}
	window.dataLayer.push(e);
	console.debug("Push event", e)
}


export default function ClubSelection(){
	const [hide, hideSet] = useState(true);
	const {search} = useLocation();
	const history = useHistory();
	const clubs = useConfig().places.values;
	const [sortedClubs, sortedClubsSet] = useState(clubs);
	const config = useConfig();
	const C = config.steps.clubSelect;
	const {tunnel, setTunnelClub, setTunnelUtm} = useTunnelData();
	const [confirm, confirmSet] = useState(false);
	const [showLimit, showLimitSet] = useState(C.showOnly);
	const [showMap, setShowMap] = useState(false);
	const [departmentOrPosition, departmentOrPositionSet] = useState({type:null});

	useEffect(() => {
		window.scrollTo(0, 0);

		pushEvent({
			'event' : 'funnel_step_1',
			'step_name' : 'Mon Club'
		});
	}, []);

	useEffect( () => {
		const searchParams = new URLSearchParams(search);
		if (searchParams.get('utm_source') || searchParams.get('utm_medium') || searchParams.get('utm_campaign')) {
			setTunnelUtm(searchParams.get('utm_source'), searchParams.get('utm_medium'), searchParams.get('utm_campaign'))
		}


		if ( clubs.length === 1 ) {
			setTunnelClub(clubs[0]);
			if( config.plans.beforeUser) {
				history.push('/plans');
			} else {
				history.push('/user');
			}
			return;
		}
		
		const msdsId = searchParams.get('msdsId');
		if( !msdsId ) {
			hideSet(false);
			return;
		}

		const found = clubs.find( c => c.msdsId == msdsId );
		if( !found ) {
			hideSet(false);
			return;
		}
		setTunnelClub(found);
		if( config.plans.beforeUser) {
			history.push('/plans');
		} else {
			history.push('/user');
		}
	}, []);

	const hav = (x) => {
		const s = Math.sin(x / 2)
		return s * s
	}

	const dist = (lat1, lng1, lat2, lng2) => {
		const PI_180 = Math.PI / 180
		const aLatRad = lat1 * PI_180
		const bLatRad = lat2 * PI_180
		const aLngRad = lng1 * PI_180
		const bLngRad = lng2 * PI_180

		const ht = hav(bLatRad - aLatRad) + Math.cos(aLatRad) * Math.cos(bLatRad) * hav(bLngRad - aLngRad)
		// since we're only interested in relative differences,
		// there is no need to multiply by earth radius or to sqrt the squared differences
		return Math.asin(ht)
	}

	useEffect( () => {
		if( !clubs )
			return;
		if( departmentOrPosition?.type === 'department' ) {
			sortedClubsSet( clubs.filter( c => c.zip.startsWith(departmentOrPosition.value) ));
			return;
		}
		if( departmentOrPosition?.type === 'geoloc' ) {
			const sorted = [...clubs];
			sorted.forEach( s => {
				s.dist = dist(s.lat, s.long, departmentOrPosition.value.lat, departmentOrPosition.value.lng);
			})
			sorted.sort( (a,b) => a.dist - b.dist);
			sortedClubsSet(sorted);
			return;
		}
		sortedClubsSet(clubs);
	}, [clubs, departmentOrPosition]);

	if( hide )
		return null;

	let filtered = sortedClubs;
	if( showLimit ) {
		filtered = sortedClubs.slice(0, showLimit);
	}

	const isDisabled = (
		!tunnel.club
		|| (!!C.confirm && !confirm)
	);

	let mapPosition = null;
	if( tunnel.club ) {
		mapPosition = [ tunnel.club.lat, tunnel.club.long ]
	} else if( departmentOrPosition?.type === 'geoloc' ) {
		mapPosition = [departmentOrPosition.value.lat, departmentOrPosition.value.lng]
	}

	const nextUrl = (club) => {
		const currentUrl = new URL(document.location);
		currentUrl.searchParams.set('msdsId', `${(club || tunnel.club)?.msdsId}`)
		currentUrl.searchParams.sort();
		if ( config.plans.beforeUser ) {
			return `/plans?${currentUrl.searchParams.toString()}`
		}
		return `/user?${currentUrl.searchParams.toString()}`;
	}

	return (
		<Step step="Votre club" loading={false}>
			{ C.promo && (
				<div className='promo'>
					{C.promo()}
				</div>
			)}
			<StyledHeader>
				<StepHeader text={C.title}/>
				{C.withMap && (
					<Button onClick={() => setShowMap(!showMap)}>
						{showMap ? C.showMap : C.hideMap}
					</Button>
				)}
			</StyledHeader>
			{!showMap && C.searchable && (
				<GeoSearch geoChange={ p => departmentOrPositionSet({type:'geoloc', value:p})} departmentChange={ d=> {
					if( !d )
						return departmentOrPositionSet(null);
					departmentOrPositionSet({type:'department', value:d});
				}} />
			)}
			{showMap &&
				<ClubsMap onSelect={setTunnelClub} clubs={clubs} selected = {tunnel.club} tunnel={tunnel} center={mapPosition}/>
			}
			{!showMap && (
				<ClubList sameLine={!!C.sameLine} clubs={filtered} selected={tunnel.club} onSelect={ (club) => {
					setTunnelClub(club);
					if( C.autoSelect ) {
						history.push(nextUrl(club));
					}
				}}/>
			)}
			{ !showMap && !!showLimit && showLimit < clubs.length && (
					<Button style={{padding: '0.5rem', marginBottom:'1rem', fontWeight: '800',cursor: 'pointer'}}
					onClick={() => showLimitSet(false)}>Voir tout</Button>
			)}
			<StyledStepFooter>
				{ !!C.confirm && (
					<aside>
						<Confirm checked={confirm} toggle={() => confirmSet(!confirm)}>
							<em>{C.confirm.txt}</em>
						</Confirm>
					</aside>
				)}
				{!C.autoSelect && <Button to={nextUrl()} variant='primary' disabled={isDisabled}>{C.nextLabel}</Button>}
			</StyledStepFooter>
		</Step>
	);
}

const StyledHeader = styled.header`
	flex-direction: row;
	align-items: center;
	padding: 0 1.5rem;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	@media(${devices.tablet.query}) {
		padding: 0 calc((100% - ${devices.tablet.size}) / 2 + 1.5rem);
	}
`;

